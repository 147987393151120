import Vue from 'vue'
import Vuex from 'vuex'
import {Loading} from "element-ui";

Vue.use(Vuex);
const state = {
    isLogin: false,
    info: {
        id: null,
        name: '',
        img: '',
        phone: '',
        role: null,
        at_name: '',
        rname: '',
        at_id:null
    },
    options: {
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    }
}

const getters = {}

const actions = {
    login(context) {
        context.commit('LOGIN');
    },
    logout(context) {
        context.commit('LOGOUT');
    },
}

const mutations = {
    LOGIN(state) {
        state.isLogin = true
        // 用vuex状态管理
        let user = JSON.parse(sessionStorage.getItem('user'));
        state.info.id = user.id
        state.info.name = user.name
        state.info.role = user.role
        state.info.phone = user.ph_num
        state.info.img = 'https://api.superfun.fun' + user.u_pic
        state.info.at_name = user.type
        state.info.rname = user.rname
        state.info.at_id = user.at_id
    },
    LOGOUT(state) {
        state.isLogin = false
        window.sessionStorage.clear()
        state.info.id = null
        state.info.name = ''
        state.info.role = null
        state.info.phone = ''
        state.info.img = ''
    },
    startLoading(state, flag) {
        let status = Loading.service(state.options)
        setTimeout(() => {
            flag ? Loading.service(state.options) : status.close()
        }, 500)

    }
}


export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});