<template>
  <!--  <div ref="vantaRef" style=height:100%;width:100%;>-->
  <div>
    <router-view></router-view>
  </div>
  <!--  </div>-->
</template>

<script>
import * as THREE from 'three'
import Globe from 'vanta/src/vanta.globe'

export default {
  name: 'App',
  mounted() {
    // this.vantaEffect = Globe({
    //   el: this.$refs.vantaRef,
    //   THREE: THREE
    // })
  },
  beforeDestroy() {
    // if (this.vantaEffect) {
    //   this.vantaEffect.destroy()
    // }
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
}

*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
}

*::-webkit-scrollbar-thumb {
  /*滚动条里面深色条*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px transparent;
  background: #6c757d;
}

*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px #ededed;
  border-radius: 5px;
  background: #ededed;
}


</style>
