import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'landPage',
        component: () => import(/* webpackChunkName: "login" */"../views/landPage.vue")

    },
    {
        path: '/ml',
        name: 'ml',
        meta: {title: '操作页面'},
        component: () => import("../views/ml.vue")
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */"../views/Home.vue"),
        children: [
            {
                path: '/home/index',
                name: 'index',
                meta: {title: '首页'},
                component: () => import(/* webpackChunkName: "manage" */"../views/Index.vue")
            },
            {
                path: '/home/car',
                name: 'car',
                meta: {title: '无人车'},
                component: () => import(/* webpackChunkName: "car" */"../views/Car.vue")
            },
            {
                path: '/home/uav',
                name: 'uav',
                meta: {title: '无人机'},
                component: () => import(/* webpackChunkName: "uav" */"../views/UAV.vue")
            },

            {
                path: '/home/manage',
                name: 'manage',
                meta: {title: '信息修改'},
                component: () => import(/* webpackChunkName: "manage" */"../views/Manage.vue")
            },

            {
                path: '/home/users',
                name: 'users',
                meta: {title: '权限修改'},
                component: () => import(/* webpackChunkName: "manage" */"../views/Users.vue")
            },
            {
                path: '/home/user',
                name: 'user',
                meta: {title: '植被信息管理'},
                component: () => import(/* webpackChunkName: "manage" */"../views/User.vue")
            },
            {
                path: '/home/page1',
                name: 'page-one',
                meta: {title: '上传照片'},
                component: () => import(/* webpackChunkName: "manage" */"../views/PageOne.vue")
            },
            {
                path: '/home/page2',
                name: 'page-two',
                meta: {title: '页面2'},
                component: () => import(/* webpackChunkName: "manage" */"../views/PageTwo.vue")
            },
            {
                path: '/home/message',
                name: 'message',
                meta: {title: '登录日志'},
                component: () => import("../views/message.vue")
            },
            {
                path: '/home/uav-op',
                name: 'uav-op',
                meta: {title: '操作页面'},
                component: () => import("../views/UAV-op.vue")
            },
            {
                path: '/home/data_show',
                name: 'data_show',
                meta: {title: '信息显示'},
                component: () => import("../views/data_show.vue")
            },
        ]
    },

    {
        path: '/Register',
        name: 'register',
        component: () => import(/* webpackChunkName: "uav" */"../views/Register.vue")
    },
    {
        path: '/ResetPassword',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "uav" */"../views/ResetPassword.vue")
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
