import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store/store'

import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'
import Axios from 'axios'
import * as echarts from 'echarts'
import dayjs from "dayjs"
import fullscreen from 'vue-fullscreen'

import CountTo from 'vue-count-to';
import 'vuesax/dist/vuesax.css'

import {WOW} from 'wowjs'
import '../node_modules/wowjs/css/libs/animate.css'

Vue.prototype.$wow = WOW;

import vsInput from "vuesax";

Vue.use(vsInput)
import vsSelect from "vuesax";
import vsCard from "vuesax"
import vsDialog from "vuesax"
import vsOption from "vuesax"

Vue.use(vsSelect)
Vue.use(vsCard)
Vue.use(CountTo)
Vue.use(vsDialog)
Vue.use(vsOption)
import ViewUI from 'view-design';

Vue.use(ViewUI)
import 'view-design/dist/styles/iview.css';
import {Loading} from 'element-ui'

let loading;

Vue.use(fullscreen)
Vue.prototype.$echarts = echarts
Vue.prototype.$dayjs = dayjs;

Vue.use(Vuex)
Axios.defaults.baseURL = 'https://server.desertcare.top';
// Axios.defaults.baseURL = 'http://localhost:3000';
global.Axios = Axios;
Vue.config.productionTip = false
Vue.use(ElementUI)

import {sha256} from 'js-sha256'


Vue.prototype.$sha256 = sha256

const startLoading = () => {  // 使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    });
};


router.beforeEach((to, from, next) => {
    let user = JSON.parse(sessionStorage.getItem('user'));
    if (!user && !(to.path === '/') && !(to.path === '/Register') && !(to.path === '/ResetPassword') && !(to.path === '/landing-page')) {
        next({path: '/'});
    } else {
        // startLoading();
        store.commit('startLoading', true)
        next()
    }
});
router.afterEach(() => {
    // endLoading();
    store.commit('startLoading', false)
})

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
    ak: 'WTcLe5VBnNUPtf9nhrlDCZL7XvX2eV4S'  //这个地方是官方提供的ak密钥
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


